<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select v-model="query.entId" filterable url="api/purchase/seller" placeholder="选择供应商" value-field="belongTo" display-field="belongToName" auto-select-first-option @change="reload" class="filter-item" style="width: 180px;" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="起始日期" end-placeholder="截止日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="reload" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="reload">搜索</el-button>
      <el-button class="filter-item" v-permission="['FINANCE_RECHARGE']" type="primary" icon="el-icon-plus" @click="add">充值</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="reload">刷新</el-button>
    </div>

    <el-card shadow="never" v-if="balanceInfo">
      <div class="h">
        <div class="money-count-item">
          <div class="fc-g">账户余额</div>
          <counter class="num" :class="balanceInfo.moneyBalance < 0 ? 'fc-e' : ''" :end-val="balanceInfo.moneyBalance / 100" :decimals="2" :duration="1000" />
        </div>
      </div>
    </el-card>

    <online-pay ref="online" :supplier="query.entId" type="balance" @paied="reload" hidden />

    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;">
      <el-table-column prop="code" label="充值单号" width="130" fixed />
      <el-table-column label="充值金额" align="right" width="120">
        <b slot-scope="scope">{{$price(scope.row.payMoney)}}</b>
      </el-table-column>
      <el-table-column prop="payFee" label="手续费" align="right" width="120" :formatter="$price" />
      <el-table-column label="充值日期" width="120" :formatter="r => {return new Date(r.payTime).format('yyyy/MM/dd');}" />
      <el-table-column label="充值方式" width="120" :formatter="r => {return paymentMethods[r.payType];}" />
      <el-table-column prop="payerAccount" label="支付账户" min-width="160" />
      <el-table-column prop="payeeAccount" label="收款账户" min-width="160" />
      <el-table-column prop="payer" label="付款人" width="80" />
      <el-table-column prop="offlinePayTime" label="付款日期" width="100" :formatter="r => {return r.offlinePayTime?new Date(r.offlinePayTime).format('yyyy/MM/dd'):'';}" />
      <el-table-column prop="info" label="备注" show-overflow-tooltip min-width="240" />
      <el-table-column label="状态" width="100" align="center" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.payType][scope.row.status].type">{{status[scope.row.payType][scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="110px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="doOnlinePay(scope.row)" v-if="scope.row.payType === 0 && scope.row.status !== 1">立即支付</el-button>
          <el-button size="mini" type="text" @click="detail(scope.row)">查看</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <e-form ref="form" />
  </div>
</template>

<script>
import { getBySupplier } from "@/api/capitalPool";
import initData from "@/mixins/initData";
import Counter from "vue-count-to";
import eForm from "./form";
import onlinePay from "@/views/assembly/onlinePay";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  components: { onlinePay, Counter, eForm },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      balanceInfo: null,
      paymentMethods: ["在线支付", "线下支付"],
      query: {
        entId: null,
        dateRange: null,
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      status: [
        [
          {
            type: "info",
            label: "待支付",
          },
          {
            type: "success",
            label: "支付成功",
          },
          {
            type: "danger",
            label: "支付失败",
          },
        ],
        [
          {
            type: "info",
            label: "待审批",
          },
          {
            type: "success",
            label: "已通过",
          },
          {
            type: "danger",
            label: "未通过",
          },
        ],
      ],
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/payForm/buyer";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.begTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      query.inOutType = "in";
      query.relationFormType = 3;
      query.sort = "createAt,desc";
      this.params = query;
      return true;
    },
    loadBalance() {
      this.balanceInfo = null;
      if (this.query.entId) {
        getBySupplier(this.query.entId).then((res) => {
          this.balanceInfo = res;
        });
      }
    },
    reload() {
      this.loadBalance();
      this.toQuery();
    },
    add() {
      this.$refs.form && this.$refs.form.resetForm();
    },
    detail(row) {
      this.$refs.form && this.$refs.form.resetForm(row);
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/payForm/buyer/download", this.params)
        .then((result) => {
          downloadFile(result, "充值明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    doOnlinePay(bill) {
      if (bill && this.$refs.online) {
        this.$refs.online.pay(bill);
      }
    },
  },
};
</script>